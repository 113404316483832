import {
  useSessionApi,
  useSessionDevice,
  useSessionEnvironment,
} from "@hornet-web-react/core/contexts/session"
import useSWR from "swr"
import { startTransition, useEffect } from "react"
import { UNKNOWN_USER_COUNTRY } from "@hornet-web-react/core/utils/constants"
import { Country } from "@hornet-web-react/core/types/session"

export const useApiLocation = (): {
  country: Country
  isCountryCodeDetected: boolean
} => {
  const { country } = useSessionDevice()
  // checked: when this runs, `currentAppUrl` is already set, because it gets set
  // at the same time as `country` in SSG page (so no need to wait for AppStart to be done)
  const { currentAppUrl } = useSessionEnvironment()
  const {
    device: { storeCountry },
  } = useSessionApi()

  // only detect location if we don't have it already
  const apiLocationUrl =
    country === UNKNOWN_USER_COUNTRY ? `${currentAppUrl}/api/location` : null

  const { data } = useSWR(apiLocationUrl, null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  })

  useEffect(() => {
    const detectedCountry = Country.safeParse(data?.geo?.country_code)

    if (detectedCountry.success && detectedCountry.data !== "unknown") {
      startTransition(() => {
        storeCountry(detectedCountry.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    country,
    isCountryCodeDetected: !!country && country !== UNKNOWN_USER_COUNTRY,
  }
}
